import 'react-dates/lib/css/_datepicker.css';

import React, { Component } from 'react';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from './TabPanel';
import ContractsTable from './ContractsTable';

import format from './format';

const emptyValue = '---';

const today = moment()
const yesterday = moment().subtract(1, 'day');
const isoFormat = 'YYYY-MM-DD';
const KALSHI_TRANSFER_DATE = '2024-11-15';
const KALSHI_CUTOFF_DISPLAY_DATE = '2025-11-16';
const BINARIES_LAUNCH_DATE = '2025-03-06';

let firstLoad = true;

function getReportDate(pathname) {
  // if root, get today's report
  if (pathname === '/') return today.format(isoFormat);

  return pathname.substring(1).replace(/\//g, '-');
}

const lxCells = [
  {
    id: 'contract',
    numeric: false,
    label: 'Contract',
    colspan: 2,
  },
  {
    id: 'last_bid',
    numeric: true,
    label: 'Last Bid',
    hide: (m) => m.isSameOrAfter(BINARIES_LAUNCH_DATE),
  },
  {
    id: 'last_ask',
    numeric: true,
    label: 'Last Ask',
    hide: (m) => m.isSameOrAfter(BINARIES_LAUNCH_DATE),
  },
  {
    id: 'min',
    numeric: true,
    label: 'Low',
    hide: (m) => m.isBefore(BINARIES_LAUNCH_DATE),
  },
  {
    id: 'max',
    numeric: true,
    label: 'High',
    hide: (m) => m.isBefore(BINARIES_LAUNCH_DATE),
  },
  {
    id: 'first_trade_price',
    numeric: true,
    label: 'First',
    hide: (m) => m.isBefore(BINARIES_LAUNCH_DATE),
  },
  {
    id: 'last_trade_price',
    numeric: true,
    label: 'Last',
    hide: (m) => m.isBefore(BINARIES_LAUNCH_DATE),
  },
  {
    id: 'volume',
    numeric: true,
    label: 'Total Volume',
  },
  {
    id: 'open_interest',
    numeric: true,
    label: 'Open Interest',
  },
  {
    id: 'vwap',
    numeric: true,
    label: 'Volume-Weighed Average Price',
  },
  {
    id: 'binary_outcome',
    numeric: false,
    label: 'Settlement Outcome'
  },
  {
    id: 'binary_settlement_index_value',
    numeric: true,
    label: 'Settlement Index Value'
  },
  {
    id: 'contract_type',
    numeric: false,
    label: 'Contract Type',
  },
];

const kalshiCells = [
  {
    id: 'contract',
    numeric: false,
    label: 'Contract',
    colspan: 5,
  },
  {
    id: 'volume',
    numeric: true,
    label: 'Total Volume',
  },
  {
    id: 'open_interest',
    numeric: true,
    label: 'Open Interest',
  },
  {
    id: 'settlement_result',
    numeric: false,
    label: 'Settlement Result',
  },
  {
    id: 'contract_type',
    numeric: false,
    label: 'Contract Type',
  },
];

class EndOfDayReport extends Component {
  state = {
    contracts: [],
    fetchError: null,
    isoDate: getReportDate(this.props.location.pathname),
    focused: false,
    isLoading: false,
    kalshiContracts: [],
    kalshiFetchError: null,
    isKalshiLoading: false,
    selectedTab: Number(sessionStorage.getItem('selectedTab') ?? 0),
  }

  componentDidMount() {
    this.getContractBasedOnPath();
  }

  componentDidUpdate(prevProps) {
    const path = this.props.location.pathname;
    const pathHasChanged = prevProps.location.pathname !== path;

    if (pathHasChanged) {
      this.getContractBasedOnPath();
    }
  }

  getContractBasedOnPath = () => {
    const path = this.props.location.pathname;
    const reportDate = getReportDate(path);
    this.getContracts(reportDate);
    this.getKalshiContracts(reportDate);
  }

  getContracts = (isoDate) => {
    const url = process.env.REACT_APP_ENV == "production" ? `https://api.ledgerx.com/trading/public-report?date=${isoDate}` : `https://api-staging.ledgerx.com/trading/public-report?date=${isoDate}`
    fetch(url, {
      accept: 'application/json',
    })
    .then(response => response.json())
    .then(responseJson => {
      firstLoad = false;

      console.log(responseJson);
      let contracts = responseJson.report_data.map(item => {
        return {
          ...item,
          block_volume: item.block_volume === undefined ? emptyValue : item.block_volume,
        }
      });

      this.setState({
        isLoading: false,
        contracts: contracts,
      })
    })
    .catch(error => {
      if (this.props.location.pathname === '/' && firstLoad) {
        this.handleDateChange(yesterday);
      } else {
        this.setState({
          isLoading: false,
          fetchError: error,
          contracts: [],
        });
      }
      firstLoad = false;
    })
  }

  getKalshiContracts = (isoDate) => {
    if (moment(isoDate).isAfter(KALSHI_TRANSFER_DATE)) {
      return this.setState({
        isKalshiLoading: false,
        kalshiFetchError: true,
        kalshiContracts: [],
      })
    }

    const url = process.env.REACT_APP_ENV == "production" ? `https://api.ledgerx.com/trading/kalshi-public-report?date=${isoDate}` : `https://api-staging.ledgerx.com/trading/kalshi-public-report?date=${isoDate}`
    fetch(url, {
      accept: 'application/json',
    })
    .then(response => response.json())
    .then(responseJson => {
      firstLoad = false;

      let contracts = responseJson.report_data;

      this.setState({
        isKalshiLoading: false,
        kalshiContracts: contracts,
      })
    })
    .catch(error => {
      if (this.props.location.pathname === '/' && firstLoad) {
        this.handleDateChange(yesterday);
      } else {
        this.setState({
          isKalshiLoading: false,
          kalshiFetchError: error,
          kalshiContracts: [],
        });
      }
      firstLoad = false;
    })
  }

  formatType = type => type.replace(/_/g, ' ')

  capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1)

  handleDateChange = date => {
    if (date) {
      const newPath = date.format('/YYYY/MM/DD')
      this.props.history.push(newPath);
    }
  }

  handleFocusChange = ({ focused }) => {
    this.setState({ focused });
  }

  handleTabChange = (event, selectedTab) => {
    sessionStorage.setItem('selectedTab', selectedTab);
    this.setState({ selectedTab });
  }

  isOutsideRange = () => false

  formatLXContracts = (contracts) => {
    return contracts.map(contract => ({
      contract: contract.contract_label,
      last_bid: contract.last_bid ? format.usdPrice(contract.last_bid) : emptyValue,
      last_ask: contract.last_ask ? format.usdPrice(contract.last_ask) : emptyValue,
      min: contract.min ? format.usdPrice(contract.min) : emptyValue,
      max: contract.max ? format.usdPrice(contract.max) : emptyValue,
      first_trade_price: contract.first_trade_price ? format.usdPrice(contract.first_trade_price) : emptyValue,
      last_trade_price: contract.last_trade_price ? format.usdPrice(contract.last_trade_price) : emptyValue,
      block_volume: contract.block_volume || 0,
      volume: contract.volume || 0,
      open_interest: contract.open_interest || 0,
      vwap: contract.vwap ? format.usdPrice(contract.vwap) : emptyValue,
      contract_type: this.capitalizeFirstLetter(this.formatType(contract.contract_type)),
      binary_outcome: contract.binary_outcome,
      binary_settlement_index_value: contract.binary_settlement_index_value ? format.usdPrice(contract.binary_settlement_index_value) : emptyValue,
    }));
  }

  formatKalshiContracts = (contracts) => {
    return contracts.map(contract => ({
      contract: contract.contract_label,
      volume: contract.volume || 0,
      open_interest: contract.open_interest || 0,
      settlement_result: contract.settlement_result || emptyValue,
      contract_type: this.capitalizeFirstLetter(this.formatType(contract.contract_type)),
    }));
  }

  render() {
    const {
      contracts,
      fetchError,
      focused,
      isoDate,
      isLoading,
      kalshiContracts,
      kalshiFetchError,
      isKalshiLoading,
      selectedTab,
    } = this.state;
    const momentDate = moment(isoDate, isoFormat);

    return (
      <div className="App-eod">
        <SingleDatePicker
          date={momentDate}
          onDateChange={this.handleDateChange}
          focused={focused}
          onFocusChange={this.handleFocusChange}
          numberOfMonths={1}
          hideKeyboardShortcutsPanel
          isOutsideRange={this.isOutsideRange}
          displayFormat={isoFormat}
        />
        <p>{`As of ${selectedTab === 1 && moment(isoDate).isSame(KALSHI_TRANSFER_DATE) ? '12pm' : '4pm'} ET on ${momentDate.format('dddd, MMMM Do, YYYY')}.`}</p>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTab} onChange={this.handleTabChange} aria-label="contracts tabs">
              <Tab label="MIAXdx" />
              {today.isBefore(KALSHI_CUTOFF_DISPLAY_DATE) && <Tab label="Kalshi" />}
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <ContractsTable
              title="MIAXdx"
              isoDate={isoDate}
              headCells={lxCells.filter(c => c.hide ? !c.hide(momentDate) : true)}
              rows={contracts}
              formatter={this.formatLXContracts}
            />
            {isLoading &&
              <p>Loading...</p>
            }
            {fetchError &&
              <p>{`There is no report for ${momentDate.format('dddd, MMMM Do, YYYY')}.`}</p>
            }
          </TabPanel>
          {today.isBefore(KALSHI_CUTOFF_DISPLAY_DATE) && (
            <TabPanel value={selectedTab} index={1}>
              <ContractsTable
                title="Kalshi"
                isoDate={isoDate}
                headCells={kalshiCells}
                rows={kalshiContracts}
                formatter={this.formatKalshiContracts}
              />
              {isKalshiLoading &&
                <p>Loading...</p>
              }
              {kalshiFetchError &&
                <p>{`There is no report for ${momentDate.format('dddd, MMMM Do, YYYY')}.`}</p>
              }
            </TabPanel>
          )}
        </Box>
      </div>
    );
  }
}

export default EndOfDayReport;
